<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "Filters",
  props: {
    platforms: {
      type: Array,
      required: false
    },
    groups: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    modalShow: false,
    selectedFilterPlatform: ''
  }),
  methods: {
    handleSearchGroup(event) {
      let groupName = event.target?.value ?? '';

      if (groupName) {
        groupName = groupName.charAt(0).toUpperCase() + groupName.slice(1);
      }

      this.$emit("searchGroup", groupName)
    },

    handleSearchGroupByPlatform(platform) {
      this.$emit("searchGroupByPlatform", platform)
    },

    openModalCreateGroup() {
      this.$emit("modalCreateGroup", !this.modalShow)
    }
  }
})
</script>

<template>

  <b-card no-body class="m-0 box-custom">
    <b-card-body>
      <b-row class="g-2">

        <b-col sm="4" class="d-flex align-items-center">
          <h5>Grupos <span class="text-muted">{{ groups?.length }}</span></h5>
        </b-col>

        <b-col sm="2" class="ms-auto">
          <div class="search-box">
            <input
                type="text"
                class="form-control bg-gray-custom"
                id="searchMemberList"
                placeholder="Buscar"
                @input="handleSearchGroup"
            >
            <i class="ri-search-line search-icon"></i>
          </div>
        </b-col>

        <b-col sm="auto">
          <div class="list-grid-nav hstack gap-1">

            <b-dropdown variant="soft-dark" right toggle-class="arrow-none" class="btn-icon fs-14" no-caret>
              <template #button-content>
                <i class="ri-more-2-fill"></i>
              </template>

              <b-dropdown-item
                  class="bg-soft-dark active"
                  @click="handleSearchGroupByPlatform('')"
              >
                Limpiar Filtro
              </b-dropdown-item>

              <b-dropdown-item
                  v-for="(platform, key) in platforms"
                  :key="key"
                  @click="handleSearchGroupByPlatform(platform)"
              >
                {{ platform }}
              </b-dropdown-item>
            </b-dropdown>

            <b-button
                variant="danger"
                class="addMembers-modal bg-red-custom"
                @click="openModalCreateGroup"
            >
              <i class="ri-add-fill me-1 align-bottom"></i>
              Agregar Grupo
            </b-button>

          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

