<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import ContainerGroups from "./container-groups.vue"
import appConfig from "../../../../app.config.json";

export default {
  name: "ContainerGroup",
  page: {
    title: 'Group',
    meta: [{name: "description", content: appConfig.description}]
  },
  components: {
    PageHeader,
    Layout,
    ContainerGroups
  },
  data: () => ({
    title: '',
    items: [
      {
        text: "Grupos",
        href: "/",
      },
      {
        text: "Todos",
        active: true,
      },
    ]
  }),

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <ContainerGroups/>

  </Layout>
</template>


