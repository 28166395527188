import gql from 'graphql-tag'

export const ADD_CONTACT_TO_GROUP_MULTIPLE = gql`
    mutation ($input: AddContactToGroupMultipleInput!) {
        addContactToGroupMultiple(input: $input) {
            id
            name
            lastName
            motherLastName
            email
            phone
            status
            area
            groups {
                id
                name
                platform
                type
                isWhatsapp
                numberEvents
            }
        }
    }
`
