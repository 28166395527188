<script>
import Detail from "@/views/group/list/detail.vue";
import Swal from "sweetalert2";
import {DELETE_GROUP} from "@/graphql/mutations/group/delete";
import {authComputed} from "@/state/helpers";
import {getFirstLetters} from "@/helpers/words";

export default {
  name: "Groups",
  props: {
    groups: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    modalEditShow: false,
    group: {}
  }),
  components: {
    Detail
  },
  methods: {
    getFirstLetters,
    selectedGroup(group) {
      this.group = group;
    },
    async deleteGroupFromBD(groupId) {
      try {
        const {data} = await this.$apollo.mutate({
          mutation: DELETE_GROUP,
          variables: {
            id: groupId
          }
        });
        return data.deleteGroup;
      } catch (error) {
        this.notification('error', error.message)
      }
    },
    deleteGroupFromTheList(groupId) {
      this.$emit('groupDeleted', groupId);
    },
    deleteGroup(groupId) {
      const $vm = this;

      Swal.fire({
        title: 'Estás seguro de eliminar el grupo permanentemente.?',
        text: "Se eliminará también todos los registros de los mensajes enviados.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#b82124',
        cancelButtonColor: '#515151',
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cerrar',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const group = await $vm.deleteGroupFromBD(groupId);
          $vm.deleteGroupFromTheList(groupId);
          $vm.notification('success', `El grupo ${group.name} se ha eliminado satisfactoriamente.`)
        }
      })
    },
    toggleFavourite() {
    },
    notification(icon, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: icon,
        title: title,
      })
    },
    openModalEditGroup(group) {
      this.$emit("modalEditGroup", {
        modalEditShow: !this.modalEditShow,
        group: group
      })
    },
    openModalModifyNumberEvents(group) {
      this.$emit("modalModifyNumberEvents", group);
    },
    openModalAddContactToGroup(group) {
      this.$emit("modalAddContactToGroup", group);
    },
    handleCustomDeleteContactGroup(group) {
      this.group = group;
    },


    openModalAddContactToGroupWhatsapp(group) {
      this.$emit("modalAddContactToGroupWhatsapp", group);
    },
  },
  computed: {
    ...authComputed,
    profileIsPlatformOrLimited() {
      return ['PLATFORM_MANAGER', 'LIMITED_MANAGER'].includes(this.currentUser?.profile);
    }
  }
}
</script>

<template>
  <b-row>
    <b-col lg="12">
      <div class="col-lg-12">
        <div id="grouplist">
          <b-row class="team-list list-view-filter" id="group-list">
            <b-col v-for="(group, index) of this.groups" :key="index">
              <b-card no-body class="team-box m-0 box-custom">

                <div class="team-cover">
                  <img src="@/assets/images/placeholders/cover.png" alt="" class="img-fluid"/>
                </div>

                <b-card-body class="p-4">
                  <b-row class="align-items-center team-row">

                    <b-col lg="4" col>
                      <div
                          class="team-profile-img"
                      >
                        <div
                            class="avatar-lg img-thumbnail rounded-circle flex-shrink-0"
                            :class="group.color.bg"
                        >

                          <div
                              class="avatar-title text-primary rounded-circle text-uppercase fs-24 position-relative"
                              :class="[group.color.bg, group.color.text]"
                              @click="openModalEditGroup(group)"
                          >
                            {{ getFirstLetters(group.name.replace(/\s/g, '')) }}

                            <span class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50 icon-circle-right">
                              <i class="ri-pencil-line text-red-custom fs-14 d-flex justify-content-around"></i>
                            </span>
                          </div>

                        </div>

                        <div class="team-content">

                          <a
                              class="member-name"
                              data-bs-toggle="offcanvas"
                              href="#member-overview"
                              aria-controls="member-overview"
                              @click="selectedGroup(group)"
                          >
                            <h5 class="fs-16 mb-1 text-black">{{ group.name }}</h5>
                          </a>

                          <p class="text-muted member-designation mb-0" v-if="group.type === 'PRIVATE'">Privado</p>
                          <p class="text-muted member-designation mb-0" v-else>Público</p>

                          <p class="text-muted member-designation mb-0">{{ group.platform }}</p>
                        </div>

                      </div>
                    </b-col>

                    <b-col lg="4" col>
                      <b-row class="text-muted text-center">
                        <b-col cols="6" class="border-end border-end-dashed">
                          <h6
                              v-if="group.isWhatsapp"
                              class="mb-1 projects-num cursor-pointer text-black"
                              @click="openModalAddContactToGroupWhatsapp(group)">
                            Agregar personas
                          </h6>

                          <h6
                              v-if="!group.isWhatsapp"
                              class="mb-1 projects-num cursor-pointer text-black"
                              @click="openModalAddContactToGroup(group)">
                            Agregar personas
                          </h6>
                        </b-col>

                        <b-col cols="6">
                          <h6
                              class="mb-1 tasks-num cursor-pointer text-black"
                              @click="openModalModifyNumberEvents(group)">
                            Modificar cantidad de eventos
                          </h6>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col class="team-settings">
                      <b-row>

                        <b-col>
                          <div class="flex-shrink-0 me-2">
                            <button
                                class="btn-icon rounded-circle btn-sm favourite-btn bg-transparent text-danger"
                                @click="toggleFavourite">
                              <i class="ri-star-line fs-22 btn-outline-danger"></i>
                            </button>
                          </div>
                        </b-col>

                      </b-row>
                    </b-col>

                    <b-col
                        lg="2"
                        class="d-grid gap-3"
                        v-if="profileIsPlatformOrLimited"
                    >
                      <router-link class="btn btn-danger view-btn bg-red-custom" :to="`group/${group.id}/message`">
                        Enviar mensaje
                      </router-link>

                      <button
                          type="button"
                          class="btn btn-outline-danger view-btn text-black"
                          @click.prevent="deleteGroup(group.id)"
                      >
                        Eliminar
                      </button>

                    </b-col>

                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <!--            <div class="text-center mb-3">-->
          <!--              <a href="javascript:void(0);" class="text-danger"><i-->
          <!--                  class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Loading....</a>-->
          <!--            </div>-->
          <!--            -->
        </div>

        <!--          <div class="py-4 mt-4 text-center" id="noresult" style="display: block;">-->
        <!--            <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"-->
        <!--                       colors="primary:#405189,secondary:#0ab39c" style="width:72px;height:72px"></lord-icon>-->
        <!--            <h5 class="mt-4">Sorry! No Result Found</h5>-->
        <!--          </div>-->


        <Detail
            :group="group"
            @delete-contact-group="handleCustomDeleteContactGroup"
        />

      </div>
    </b-col>
  </b-row>
</template>
