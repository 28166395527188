<script>
import {getIdWithoutHash} from "@/helpers/helper";
import {authComputed} from "@/state/helpers";
import Swal from "sweetalert2";
import {DELETE_CONTACT_GROUP} from "@/graphql/mutations/group/deleteContactGroup";
import {has, values} from "lodash";
import formatGraphQLErrors from "@/errors/formatGraphQLErrors";
import {getFirstLetters} from "@/helpers/words";

export default {
  name: "Detail",
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    error: null,
  }),
  methods: {
    getFirstLetters,
    getIdWithoutHash,
    async deleteContactGroup(contact) {
      try {
        const $vm = this;

        Swal.fire({
          title: `Estás seguro de desea expulsar al contact ${contact.name} del grupo permanentemente.?`,
          text: `Se eliminará el contacto ${contact.name} de todas las aplicaciones.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#b82124',
          cancelButtonColor: '#515151',
          confirmButtonText: 'Expulsar',
          cancelButtonText: 'Cerrar',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {

            const {data} = await $vm.$apollo.mutate({
              mutation: DELETE_CONTACT_GROUP,
              variables: {
                input: {
                  groupId: $vm.group.id,
                  contactId: contact.id
                }
              }
            });

            const group = data.deleteContactGroup;
            $vm.notification('success', `El contacto ${contact.name} ${contact.lastName} se ha expulsado del grupo ${group.name}.`);
            this.$emit('deleteContactGroup', group);
            return group;
          }
        })

      } catch (errors) {
        let dataError = has(errors, 'graphQLErrors') ? formatGraphQLErrors(errors.graphQLErrors) : errors.message

        if (has(dataError, 'category') && dataError.category === 'validation') {
          dataError = values(dataError.errors);
        }

        if (has(dataError, 'category') && dataError.category === 'user') {
          dataError = dataError.message
        }

        if (has(dataError, 'category') && dataError.category === 'graphql') {
          dataError = dataError.message
        }

        this.error = dataError;
        this.notification('error', dataError);
      }
    },
    notification(type, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: type,
        title: title,
      })
    },
  },
  computed: {
    ...authComputed,
    profileIsPlatformOrLimited() {
      return ['PLATFORM_MANAGER', 'LIMITED_MANAGER'].includes(this.currentUser?.profile);
    }
  }
}
</script>

<template>
  <div class="offcanvas offcanvas-end border-0" tabindex="-1" id="member-overview">
    <div class="offcanvas-body profile-offcanvas p-0">

      <div class="team-cover cover-custom"></div>

      <div class="p-3">
        <div class="team-settings">
          <b-row>

            <b-col class="text-end dropdown">
              <a href="javascript:void(0);" id="dropdownMenuLink14" data-bs-toggle="dropdown"
                 aria-expanded="false">
                <i class="ri-more-fill fs-17"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink14">
              </ul>
            </b-col>

          </b-row>
        </div>
      </div>

      <div class="p-3 text-center">
        <div
            class="avatar-lg img-thumbnail rounded-circle mx-auto profile-img flex-shrink-0 cursor-pointer"
            :class="group.color?.bg"
        >
          <div
              class="avatar-title text-primary rounded-circle text-uppercase fs-24 position-relative"
              :class="[group.color?.bg, group.color?.text]"
              data-bs-toggle="offcanvas"
              aria-controls="member-overview"
          >
            <span class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50 icon-circle-left">
              <i class="ri-star-line text-red-custom fs-20 d-flex justify-content-around"></i>
            </span>

            {{ getFirstLetters(group.name?.replace(/\s/g, '')) }}

            <span class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50 icon-circle-right">
              <i class="ri-pencil-line text-red-custom fs-20 d-flex justify-content-around"></i>
            </span>
          </div>
        </div>



        <div class="mt-3">
          <h5 class="fs-15 profile-name">{{ group.name }}</h5>
          <p class="text-muted profile-designation" v-if="group.type === 'PRIVATE'">Privado</p>
          <p class="text-muted profile-designation" v-else>Público</p>
        </div>
      </div>

      <b-row class="g-0 text-center">
        <b-col cols="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1 profile-project">{{ group.contacts?.length }}</h5>
            <p class="text-muted mb-0">Contactos</p>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1 profile-task" v-if="group.numberEvents !== null">{{ group.numberEvents }}</h5>
            <h5 class="mb-1 profile-task" v-else>N/A</h5>
            <p class="text-muted mb-0">Cantidad de Eventos</p>
          </div>
        </b-col>
      </b-row>

      <div class="p-3">
        <h5 class="text-black fs-15 mb-4">Información detallada</h5>

        <div class="mb-4">
          <p class="text-black text-uppercase fw-semibold fs-15 mb-2">Identificador</p>
          <h6 class="text-muted fs-13">{{ getIdWithoutHash(group.id) }}</h6>
        </div>

        <div class="mb-4">
          <p class="text-black text-uppercase fw-semibold fs-15 mb-2">Es whatsapp</p>
          <h6 class="text-muted fs-13" v-if="group.isWhatsapp">Si</h6>
          <h6 class="text-muted fs-13" v-else>No</h6>
        </div>

        <div class="mb-4">
          <p class="text-black text-uppercase fw-semibold fs-15 mb-2">Plataforma</p>
          <h6 class="text-muted fs-13" v-if="group.platform">{{ group.platform }}</h6>
          <h6 class="text-muted fs-13" v-else>N/A</h6>
        </div>

        <div class="mb-4">
          <p class="text-black text-uppercase fw-semibold fs-12 mb-2">Cantidad de Eventos</p>
          <h6 class="text-muted fs-13 mb-0" v-if="group.numberEvents !== null">{{ group.numberEvents }}</h6>
          <h6 class="text-muted fs-13 mb-0" v-else>N/A</h6>
        </div>

      </div>

      <div class="p-3 border-top">
        <h5 class="fs-15 mb-4">Contactos</h5>

        <h5
            class="fs-15 mb-4 text-danger text-uppercase"
            v-if="group.contacts?.length === 0"
        >
          No hay contactos agregados en el grupo
        </h5>

        <div
            class="d-flex mb-3"
            v-for="(contact, key) in group.contacts"
            :key="key"
        >
          <div class="flex-shrink-0 avatar-xs">
            <div class="avatar-title bg-soft-danger text-secondary rounded fs-22">
              <i class="lab las la-users text-red-custom"></i>
            </div>
          </div>

          <div class="flex-grow-1 ms-3">
            <h6 class="mb-1">
              <a class="text-danger">
                {{ contact.name }} {{ contact.lastName }}
              </a>
            </h6>
            <p class="text-muted mb-0">
              Contacto - {{ getIdWithoutHash(contact.id) }}
            </p>
          </div>

          <div class="flex-shrink-0">
            <div class="d-flex align-items-center gap-1">
              <b-button
                  type="button"
                  variant="customRed"
                  size="sm"
                  class="fs-10"
                  @click="deleteContactGroup(contact)"
              >
                <i class="ri-delete-bin-6-fill"></i>
              </b-button>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div
        class="offcanvas-foorter border p-3 hstack gap-3 text-center position-relative"
        v-if="profileIsPlatformOrLimited"
    >
      <router-link class="btn btn-light btn-md w-100 d-block bg-red-custom text-white" :to="`group/${group.id}/message`">
        <i class="ri-question-answer-fill align-bottom ms-1"></i>
        Enviar mensaje
      </router-link>
    </div>

  </div>
</template>

