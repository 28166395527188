import gql from 'graphql-tag'

export const UPDATE_GROUP = gql`
    mutation ($id: ID!, $input: UpdateGroupInput!) {
        updateGroup(id: $id, input: $input) {
            id
            name
            platform
            type
            isWhatsapp
            numberEvents
            contacts {
                id
                name
                lastName
                motherLastName
                email
                phone
                status
                area
            }
        }
    }
`
