<script>
import {defineComponent} from "vue";
import VueMultiselect from 'vue-multiselect'
import {LIST_GROUP} from "@/graphql/queries/groups/list";
import {UPDATE_GROUP} from "@/graphql/mutations/group/update";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {getPlatforms} from "@/helpers/helper";


export default defineComponent({
  name: 'Edit Group',
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    platforms: {
      type: Array,
      required: false
    }
  },
  components: {
    VueMultiselect
  },
  data: () => ({
    modalEditShow: false,
    dataPlatforms: [],
    submitted: false,
    infoGroup: null,
    form: {
      name: null,
      type: "PRIVATE",
      platform: null,
      isWhatsapp: false
    },
  }),
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage("El nombre del grupo es requerido", required),
        },
        platform: {
          required: helpers.withMessage("La selección de una plataforma es requerida", required),
        },
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.modalEditShow = this.modal;
      this.fillForm();
      this.loadPlatforms();
    }, 800)
  },
  methods: {
    fillForm() {
      this.infoGroup = this.group;

      this.form.name = this.infoGroup?.name;
      this.form.platform = this.infoGroup?.platform;
      this.form.isWhatsapp = this.infoGroup?.isWhatsapp;
    },
    async loadPlatforms() {
      this.dataPlatforms = this.platforms;

      if (this.dataPlatforms.length === 0) {
        await this.fetchGroups()
      }
    },
    async fetchGroups() {
      try {
        const {data} = await this.$apollo.query({query: LIST_GROUP});
        const groups = data.groups;

        this.dataPlatforms = getPlatforms(groups);

        return groups;
      } catch (error) {
        return [];
      }
    },
    async updateGroup() {
      try {
        this.submitted = true;
        this.v$.$touch();

        const {data} = await this.$apollo.mutate({
          mutation: UPDATE_GROUP,
          variables: {
            id: this.group.id,
            input: this.form
          }
        });

        this.infoGroup = data.updateGroup;
        this.$emit('groupUpdated', this.infoGroup);
        this.closedModal();
        this.notification();
      } catch (error) {
        return [];
      }
    },
    closedModal() {
      this.modalEditShow = false;
    },
    notification() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: `El grupo ${this.infoGroup.name} se ha actualizado satisfactoriamente.`,
      })
    },
    addPlatform(newPlatform) {
      this.dataPlatforms.push(newPlatform);
    }
  },
  watch: {
    group: function() {
      this.fillForm();
    },
    modal: function (value) {
      this.modalEditShow = value;
    },
    modalEditShow: function (newValue) {
      this.$emit('modalClosedEditGroup', newValue);
    }
  },
  computed: {
    listPlatforms() {
      return this.dataPlatforms;
    }
  }
})
</script>

<template>
  <b-modal
      v-model="modalEditShow"
      hide-footer
      hide-header
      hide-header-close
      content-class="border-0"
      class="v-modal-custom"
      centered
  >
    <form
        autocomplete="off"
        id="group-update-form"
        class="needs-validation"
        @submit.prevent="updateGroup"
    >
      <b-row>
        <b-col lg="12">
          <div class="px-1 pt-1">
            <div class="modal-team-cover position-relative mb-0 mt-n4 mx-n4 rounded-top overflow-hidden">

              <img src="@/assets/images/placeholders/cover.png" alt="" id="cover-img" class="img-fluid"/>

              <div class="d-flex position-absolute start-0 end-0 top-0 p-3">
                <div class="flex-grow-1">
                  <h5 class="modal-title text-white" id="createGroupLabel">Editar Grupo</h5>
                </div>

                <div class="flex-shrink-0">
                  <div class="d-flex gap-3 align-items-center">
                    <button
                        type="button"
                        class="btn-close btn-close-white"
                        id="createMemberBtn-close"
                        @click="modalEditShow = !modalEditShow">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mb-4 mt-n5 pt-2">
            <div class="position-relative d-inline-block">
              <div class="avatar-lg">
                <div
                    id="cover-img"
                    class="avatar-lg img-thumbnail rounded-circle mx-auto profile-img flex-shrink-0 cursor-pointer bg-gray-custom"
                >
                  <div
                      class="avatar-title rounded-circle text-uppercase fs-24 position-relative bg-gray-custom text-gray-custom"
                  >
                    ?
                    <span class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50">
                    <i class="ri-pencil-line text-red-custom fs-20 d-flex justify-content-around"></i>
                  </span>
                  </div>

                </div>

              </div>

            </div>
          </div>

          <div class="mb-3">
            <label for="groupName" class="form-label">Nombre de Grupo</label>
            <input
                type="text"
                class="form-control"
                id="groupName"
                placeholder="Escriba el nombre del grupo"
                v-model="form.name"
                :class="{
                  'is-invalid': submitted && v$.form.name.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.name.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>

          </div>

          <div class="mb-3">
            <label for="platform" class="form-label">Plataforma</label>
            <VueMultiselect
                v-model="form.platform"
                placeholder="Seleccione una plataforma"
                :options="listPlatforms"
                :taggable="true"
                @tag="addPlatform"
                :class="{
                  'is-invalid': submitted && v$.form.platform.$error,
                }"
            />
            <div v-for="(item, index) in v$.form.platform.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="mb-3">
            <div class="form-check form-switch form-switch-danger">
              <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="SwitchCheck5"
                  v-model="form.isWhatsapp"
              >
              <label class="form-check-label" for="SwitchCheck5">Es Whatsapp</label>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button type="button" variant="light" @click="modalEditShow = false">Cerrar</b-button>

            <b-button
                type="submit"
                variant="danger"
                id="updateGroup"
            >
              Modificar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </form>

  </b-modal>
</template>

<style lang="scss">
.multiselect {
  padding: 0 !important;
}
</style>
