<script>
import Filters from "./filters.vue"
import Groups from "./groups.vue"
import CreateGroup from "@/views/modals/group/create.vue"
import EditGroup from "@/views/modals/group/edit.vue"
import ModifyNumberEvents from "@/views/modals/group/modify-number-events.vue";
import AddContactToGroup from "@/views/modals/group/add-contact-to-group.vue";
import AddContactToGroupWhatsapp from "@/views/modals/group/add-contact-to-group-whatsapp.vue";

import {LIST_GROUP} from "@/graphql/queries/groups/list";
import {getFirstLetters} from "@/helpers/words";
import {getPlatforms} from "@/helpers/helper";
import {defineComponent} from "vue";
import {randomColor} from "@/helpers/bg-colors";

export default defineComponent({
  name: "ContainerGroups",
  data: () => ({
    modalShow: false,
    modalEditShow: false,
    modalModifyNumberEventsShow: false,
    modalAddContactToGroupShow: false,
    modalAddContactToGroupWhatsappShow: false,
    groups: [],
    platforms: [],
    groupEdit: null,
  }),
  mounted() {
    this.fetchGroups();
  },
  components: {
    Filters,
    Groups,
    CreateGroup,
    EditGroup,
    ModifyNumberEvents,
    AddContactToGroup,
    AddContactToGroupWhatsapp
  },
  methods: {
    getFirstLetters,
    async fetchGroups(filter) {
      try {
        let options = {
          query: LIST_GROUP
        }

        if (filter) {
          options = {...options, variables: filter}
        }

        const {data} = await this.$apollo.query(options);

        this.groups = (data.groups || []).map(group => {
          return {
            ...group,
            color: randomColor()
          }
        });

        if (this.platforms.length === 0) {
          this.platforms = getPlatforms(this.groups);
        }

        return this.groups;
      } catch (error) {
        return [];
      }
    },
    handleCustomSearchGroup(name) {
      this.fetchGroups({name: name})
    },
    handleCustomSearchGroupByPlatform(platform) {
      (platform === '') ?
          this.fetchGroups() :
          this.fetchGroups({platform: platform})
    },
    handleCustomModalCreateGroup(modalShow) {
      this.modalShow = modalShow
    },
    handleCustomClosedModalCreateGroup(value) {
      this.modalShow = value
    },
    handleCustomGroupCreated(group) {
      this.groups = [...this.groups, {...group}]
    },
    handleCustomDeletedGroup(groupId) {
      let allGroups = [...this.groups];

      const indexToRemove = allGroups.findIndex(group => group.id === groupId);
      allGroups.splice(indexToRemove, 1);

      this.groups = [...allGroups];
    },
    handleCustomModalEditGroup({modalEditShow, group}) {
      this.modalEditShow = modalEditShow;
      this.groupEdit = group;
    },
    handleCustomClosedModalEditGroup(value) {
      this.modalEditShow = value
    },
    handleCustomGroupUpdated(group) {
      let allGroups = [...this.groups];
      const indexTo= allGroups.findIndex(item => item.id === group.id);

      allGroups.splice(indexTo, 1, group);

      this.groups = [...allGroups];
    },
    handleCustomModalModifyNumberEvents(group) {
      this.modalModifyNumberEventsShow = true
      this.groupEdit = group;
    },
    handleCustomClosedModalModifyNumberEvents(value) {
      this.modalModifyNumberEventsShow = value
    },

    handleCustomModalAddContactToGroup(group) {
      this.modalAddContactToGroupShow = true;
      this.groupEdit = group;
    },
    handleCustomClosedModalAddContactToGroup(value) {
      this.modalAddContactToGroupShow = value;
    },

    handleCustomModalAddContactToGroupWhatsapp(group) {
      this.modalAddContactToGroupWhatsappShow = true;
      this.groupEdit = group;
    },
    handleCustomClosedModalAddContactToGroupWhatsapp(value) {
      this.modalAddContactToGroupWhatsappShow = value;
    }
  },
  computed: {
    dataGroups() {
      return this.groups;
    }
  }
});
</script>

<template>
  <Filters
      v-if="platforms.length > 0"
      :platforms="platforms"
      :groups="dataGroups"
      @search-group="handleCustomSearchGroup"
      @search-group-by-platform="handleCustomSearchGroupByPlatform"
      @modal-create-group="handleCustomModalCreateGroup"
  />

  <Groups
      :groups="dataGroups"
      @group-deleted="handleCustomDeletedGroup"
      @modal-edit-group="handleCustomModalEditGroup"
      @modal-modify-number-events="handleCustomModalModifyNumberEvents"
      @modal-add-contact-to-group="handleCustomModalAddContactToGroup"
      @modal-add-contact-to-group-whatsapp="handleCustomModalAddContactToGroupWhatsapp"
  />

  <!--  Loading modals-->
  <CreateGroup
      :modal="modalShow"
      :platforms="platforms"
      @modal-closed-create-group="handleCustomClosedModalCreateGroup"
      @group-created="handleCustomGroupCreated"
  />

  <EditGroup
      v-if="groupEdit !== null"
      :modal="modalEditShow"
      :group="groupEdit"
      :platforms="platforms"
      @modal-closed-edit-group="handleCustomClosedModalEditGroup"
      @group-updated="handleCustomGroupUpdated"
  />

  <ModifyNumberEvents
      v-if="modalModifyNumberEventsShow"
      :modal="modalModifyNumberEventsShow"
      :group="groupEdit"
      @modal-closed-modify-number-events="handleCustomClosedModalModifyNumberEvents"
      @group-updated="handleCustomGroupUpdated"
  />

  <AddContactToGroup
      v-if="modalAddContactToGroupShow"
      :modal="modalAddContactToGroupShow"
      :group="groupEdit"
      @modal-closed-add-contact-to-group="handleCustomClosedModalAddContactToGroup"
      @group-updated="handleCustomGroupUpdated"
  />

  <AddContactToGroupWhatsapp
      v-if="modalAddContactToGroupWhatsappShow"
      :modal="modalAddContactToGroupWhatsappShow"
      :group="groupEdit"
      @modal-closed-add-contact-to-group-whatsapp="handleCustomClosedModalAddContactToGroupWhatsapp"
      @group-updated="handleCustomGroupUpdated"
  />

</template>

