import gql from 'graphql-tag'

export const DELETE_GROUP = gql`
    mutation ($id: ID!) {
        deleteGroup(id: $id) {
            id
            name
            platform
            type
            isWhatsapp
            numberEvents
            contacts {
                id
                name
                lastName
                motherLastName
                email
                phone
                status
                area
            }
        }
    }
`
